<template>
  <section>
    <b-row>
      <b-col cols="12">
        <asset-list />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import AssetList from '@/views/main/asset/asset-list/AssetList.vue'

export default {
  components: {
    BRow,
    BCol,
    AssetList
  },
  data() {
    return {
      data: {},
    }
  },
  created() {
  },
}
</script>

<style lang="scss">
</style>
