<template>
  <div>
    <!--Filters-->
    <transition name="fade" mode="out-in">
      <b-card key="3" v-if="showFilters" no-body class="mb-2 ">
        <b-card-header class="d-flex justify-content-between pl-1 pt-1">
          <h5>
            {{ $t('Filters') }}
          </h5>
          <b-avatar @click="showFilters = false" v-b-tooltip.hover :title="titleTootipBtnCloseFilter" class="mr-1"
                    button variant="danger" size="30px">
            <feather-icon icon="XIcon"/>
          </b-avatar>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <h5 class="font-weight-bold">{{ $t('Owner') }}</h5>
              <vue-autosuggest v-model="searchOwner" :suggestions="filteredOptions" :input-props="inputProps"
                               @selected="selectHandler" @input="onInputChange">
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                  <div v-show="suggestion.item.ownerName">
                    <span class="text-muted">{{ $t('Belongs to: ') }}</span>
                    <span class="font-weight-bold"> {{ suggestion.item.ownerName }} </span>
                  </div>
                </template>
              </vue-autosuggest>
            </b-col>
            <b-col cols="12" md="6">
              <h5 class="font-weight-bold">{{ $t('Status') }}</h5>
              <multiselect v-model="chosenStatus" :options="options" :multiple="true" :close-on-select="false"
                           :clear-on-select="false" :preserve-search="true" :placeholder="placeholderSelect"
                           label="description" track-by="description" :preselect-first="false">
                <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
                                                                                         v-if="values.length && !isOpen">{{
                    values.length
                  }} &nbsp; {{ $t('options selected') }} </span></template>
              </multiselect>
            </b-col>

          </b-row>

        </b-card-body>
      </b-card>

    </transition>

    <!-- Table Container Card -->
    <b-card no-body>

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('Show') }}</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
                      :clearable="false" class="per-page-selector d-inline-block ml-50 mr-1"/>
            <label>{{ $t('entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" :placeholder="$t('Search ...')"/>
              <span v-show="!showFilters" class="float-right ml-1">
                            <b-button class="btn-icon" @click="showFilters = true" variant="primary" v-b-tooltip.hover
                                      :title="titleTootipBtnFilter">
                                <feather-icon icon="FilterIcon"/>
                            </b-button>
                        </span>

              <!--   
              <b-button class="ml-1" v-if="user.role == 'TrackingOwner' || user.role == 'ServiceSharer' || user.role == 'Dealer'" variant="primary" :to="{ name: 'register-asset' }">
                  <span class="text-nowrap">{{ $t('Add Asset')}}</span>
              </b-button>   -->

              <!--       -->
              <b-dropdown variant="primary" class="ml-1">
                <template #button-content>
                  {{ $t('Options') }}
                </template>
                <b-dropdown-item v-if="user.role == 'TRCKOWN' || user.role == 'SERVSHAR' || user.role == 'DEALER'"
                                 :to="{ name: 'register-asset' }">{{ $t('Add Asset') }}
                </b-dropdown-item>
                <b-dropdown-item v-if="user.role != 'SIMPlVI'" v-b-tooltip.hover :title="titleFuellingBtn"
                                 :to="{ name: 'customer-alarms' }">{{ $t('Alarms') }}
                </b-dropdown-item>
                <b-dropdown-item @click="exportXls" v-b-tooltip.hover :title="titleFuellingBtn">
                  {{ $t('Export Sheet') }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table ref="refAssetListTable" :items="fetchAssets" responsive :fields="tableColumns" primary-key="id"
               :sort-by.sync="sortBy" show-empty :empty-text="emptyText" :sort-desc.sync="sortDesc"
               class="position-relative">

        <!-- Column: Owner -->
        <template #head(ownername)>
          {{ $t('Owner') }}
        </template>
        <template #cell(ownername)="data">
          <div>
            <small>

              {{ $t('Owner') }}: <strong>{{ data.item.customerName }}</strong>
            </small>
          </div>
          <div v-show="data.item.dealerName">
            <small class="text-muted">{{ $t('Dealer') }}: <strong>{{ data.item.dealerName }}</strong></small>
          </div>
        </template>

        <!-- Column: Cost Center -->
        <template #head(costCenter)>
          {{ $t('Cost Center') }}
        </template>
        <template #cell(costCenter)="data">
          <div>
            {{ data.item.costCenter }}
          </div>
        </template>

        <!-- Column: Identifier -->
        <template #head(chassis)>
          {{ $t('Identifier') }}
        </template>
        <template #cell(chassis)="data">
          <b-link :to="{ name: 'preview-asset', params: { id: data.item.id }}" class="font-weight-bold ">
            <span class="text-uppercase">{{ data.item.plate != '' ? data.item.plate : data.item.chassisNumber }}</span>

          </b-link>
        </template>

        <!-- Column: lastConnectionTime -->
        <template #head(lastconnection)>
          {{ $t('Last Connection') }}
        </template>
        <template #cell(lastconnection)="data">
          <div>
            <small> {{ $t('Last Connection') }}: <strong>{{ formatDate(data.item.lastConnectionTime) }}</strong></small>

          </div>

        </template>

        <!-- Column: WorkStatus -->
        <template #head(workstatus)>
          <feather-icon icon="TrendingUpIcon" class="mx-auto"/>
        </template>

        <template #cell(workstatus)="data">

          <b-badge :variant="`${setColor(data.item.lastWorkstatus)}`" :class="`badge-glow ` ">
            <span :class="resolveClass(data.item.lastWorkstatus)"> {{
                resolveTextStatus(data.item.lastWorkstatus)
              }}</span>
          </b-badge>
          <b-badge variant="danger" class="badge-glow pulse" v-show="data.item.pan">
            <span> {{ $t('Panic') }}</span>
          </b-badge>

        </template>

        <!-- Column: Model -->
        <template #head(modelName)>
          {{ $t('Model') }}
        </template>
        <template #cell(modelName)="data">
          <!--
              <b-media vertical-align="center">
              <template #aside>
                  <b-avatar :style="resolveColorNoConnection(data.item.lastWorkstatus)" size="48" :src="resolveIcon(data.item.assetType, data.item.iconUrl)" :text="avatarText(data.item.model)" :variant="`${resolveModelAvatarVariant(data.item.lastWorkstatus)}`" />
              </template>
              <span class="font-weight-bold d-block text-nowrap">
                  {{ data.item.manufacturerName }} - {{ data.item.model }}
              </span>

          </b-media>
          -->

          <div class="media">

            <b-avatar class="mr-3" :style="resolveColorNoConnection(data.item.lastWorkstatus)" size="48"
                      :src="resolveIcon(data.item.assetType, data.item.iconUrl)" :text="avatarText(data.item.model)"
                      :variant="`${resolveModelAvatarVariant(data.item.lastWorkstatus)}`"/>
            <div class="media-body pagination-centered align-self-center">
                        <span class="font-weight-bold">
                            {{ data.item.manufacturerName }} - {{ data.item.model }}
                        </span>
            </div>

          </div>
        </template>

        <!-- Column: Actions -->
        <template #head(actions)>
          {{ $t('Actions') }}
        </template>
        <template #cell(actions)="data">

          <div class="text-nowrap">

            <!-- Dropdown -->
            <b-dropdown variant="link" toggle-class="p-0" no-caret :right="$store.state.appConfig.isRTL">

              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
              </template>
              <b-dropdown-item :to="{ name: 'preview-asset', params: { id: data.item.id } }">
                <feather-icon icon="ArrowRightCircleIcon"/>
                <span class="align-middle ml-50">{{ $t('Open') }}</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'register-asset', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">{{ $t('Edit') }}</span>
              </b-dropdown-item>

              <b-dropdown-item @click="modalDelete(data.item.id)"
                               v-if="user.role == 'TRCKOWN' || user.role == 'SERVSHAR' || user.role == 'DEALER'">
                <feather-icon icon="Trash2Icon"/>
                <span class="align-middle ml-50">{{ $t('Delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{
                dataMeta.to
              }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination v-model="currentPage" :total-rows="totalAssets" :per-page="perPage" first-number last-number
                          class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <!--Modal Create Export-->
    <b-modal ref="modal-export" size="sm" centered hide-header hide-footer no-close-on-esc no-close-on-backdrop
             hide-header-close>
      <div class="mx-3 text-center">
        <h3>{{ $t('Preparing report') }}...</h3>
        <b-spinner variant="primary" label="Text Centered"/>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BCardBody,
  BCardHeader,
  BSpinner
} from 'bootstrap-vue'
import {
  avatarText
} from '@core/utils/filter'
import vSelect from 'vue-select'
import {
  onUnmounted
} from '@vue/composition-api'
import store from '@/store'
import useAssetList from './useAssetList'

import assetStoreModule from '../assetStoreModule'
import {
  VueAutosuggest
} from 'vue-autosuggest'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BCardBody,
    BCardHeader,
    vSelect,
    VueAutosuggest,
    Multiselect,
    BSpinner
  },
  data() {
    return {
      emptyText: this.$t('No matching records found'),
      showFilters: false,
      titleTootipBtnFilter: this.$t('Open Filters'),
      titleTootipBtnCloseFilter: this.$t('Close Filters'),

      searchOwner: '',
      datasuggest: [],
      filteredOptions: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: "Search owner ...",
      },
      limit: 3,
      selected: null,
      //customerId:''

      //chosenStatus:[],
      options: []

    }
  },
  methods: {


    showModalExport() {
      this.$refs['modal-export'].show()
    },
    hideModalExport() {
      this.$refs['modal-export'].hide()
    },

    async exportXls() {
      //this.exportPdf = true
      this.showModalExport()
      const res = await store.dispatch('app-asset/exportAssetsExcel')
          .then((response) => {
            var date = new Date().toLocaleDateString()
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `assets_${date}.xls`);
            document.body.appendChild(link);
            link.click();
            //this.exportPdf = false
            this.hideModalExport()
          });
    },


    async exportPDF() {
      //this.exportPdf = true
      this.showModalExport()
      const res = await store.dispatch('app-asset/exportAssetsPDF')
          .then((response) => {
            var date = new Date().toLocaleDateString()
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `assets_${date}.pdf`);
            document.body.appendChild(link);
            link.click();
            //this.exportPdf = false
            this.hideModalExport()
          });
    },

    resolveTextStatus(status) {
      if (status == 'neverconnected' || status == '') return this.$t('Never Connected')
      return this.$t(status)
    },

    fetchTypesWorkStatus() {
      store
          .dispatch(`app-asset/fetchTypesWorkStatus`)
          .then(response => {
            this.options = response.data.data

          })
          .catch(() => {
            console.log('erro')
          })
    },

    selectHandler(option) {
      this.selected = option.item
      this.filteredOptions = []
      this.searchOwner = option.item.name
      this.customerId = option.item.id

      console.log(`option selecionado `, this.customerId)
    },

    onInputChange(text) {
      if (text === '' || text === undefined || text.length < 3) {
        this.customerId = ''
        return
      }

      store.dispatch('app-asset/fetchCustomers', {
        q: this.searchOwner,

      })
          .then(response => {

            this.filteredOptions = [{
              data: response.data.data,
            }]

          })
          .catch(error => {

            console.log(error)

          })
    },

    resolveClass(status) {
      if (status == 'noconnection' || status == '' || status == 'neverconnected') return 'noconnection'
      return ''
    },

    setColor: function (status) {

      if (status === 'failure') return 'danger'
      if (status === 'off') return 'secondary'
      if (status === 'noconnection') return 'light'
      if (status === 'severe') return 'warning'
      if (status === 'iddle') return 'info'
      if (status === 'on') return 'success'
      return 'light'
    },

    resolveColorNoConnection(status) {
      if (status == 'noconnection' || status == '' || status == 'neverconnected') return {
        'background-color': '#f8f9fa'
      }
      return ''
    },

    event_tlm: function (content) {
      console.log('Teste', content)

      var panic = {
        assetId: content.assetId,
        pan: content.data.pan,
      }

      var asset = {
        id: content.assetId,


        lastPositionTime: content.deviceDateTime,
        lastWorkstatus: content.workStatus
      };


      this.realtimeUpdatePan(panic);
      this.realtimeUpdateAsset(asset);
    },

    realtimeUpdatePan(panic) {
      console.log("Pan", panic);
      if (panic == null || panic.assetId == undefined || panic.assetId == null || panic.pan == undefined || panic.pan == null) return

      var self = this;
      for (var i = 0; i < self.$refs.refAssetListTable.localItems.length; i++) {
        if (self.$refs.refAssetListTable.localItems[i].id == panic.assetId) {

          self.$refs.refAssetListTable.localItems[i].pan = panic.pan;
          console.log("Pan Ref", self.$refs.refAssetListTable.localItems[i].pan);
        }
      }
    },

    registerEventBusListeners: function () {
      this.$root.$on("tlm", this.event_tlm);
    },

    deregisterEventBusListeners: function () {
      this.$root.$off("tlm", this.event_tlm);
    },

    realtimeUpdateAsset: function (asset) {
      if (asset == null || asset.lastWorkstatus == null || asset.lastPositionTime == null) return;
      //console.log(asset, '0')
      console.log(`REFS1`, this.$refs.refAssetListTable)
      //console.log(`REFS1`,this.$refs.refAssetListTable.localItems[0].plate)
      //this.$refs.refAssetListTable.localItems[0].plate= `TRE 9874`

      var self = this;
      for (var i = 0; i < self.$refs.refAssetListTable.localItems.length; i++) {
        if (self.$refs.refAssetListTable.localItems[i].id == asset.id) {

          self.$refs.refAssetListTable.localItems[i].lastWorkstatus = asset.lastWorkstatus;
          self.$refs.refAssetListTable.localItems[i].lastConnectionTime = asset.lastPositionTime;
          self.$refs.refAssetListTable.localItems[i].lastTelemetryTime = asset.lastPositionTime;

        }
      }

    },

    formatDate(myDate) {

      const d = new Date(myDate);
      let dt = d.toLocaleDateString('pt-br');

      const hm = d.toLocaleTimeString().substring(0, 5)

      if (!myDate) return this.$t('Never Connected')

      return `${dt} - ${hm}`;

      //return `${dt} - ${d.getHours()}:${d.getMinutes()}`
    },

    modalDelete(myId) {
      this.$swal({
        title: this.$t('Are you sure?'),
        text: this.$t("You can't revert your action"),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        showCloseButton: true,
        showLoaderOnConfirm: true,
        customClass: {
          cancelButton: 'cancelBtn',
          confirmButton: 'mr-1',
        }
      }).then((result) => {
        if (result.value) {
          console.log('ID', myId)
          this.deleteAsset(myId)

        } else {
        }
      })
    },

    deleteAsset(myId) {
      store.dispatch('app-asset/deleteAsset', {
        id: myId
      })
          .then(response => {
            this.$swal(this.$t('Deleted'), this.$t('You successfully deleted'), 'success')
                .then((result) => {
                  if (result.value) {
                    this.refetchData()

                  } else {
                  }
                })

          })
          .catch(error => {
            this.$swal(this.$t('Error'), this.$t('An error occurred while trying to delete'), 'error')
            console.log(error);
          })
    }

  },
  mounted() {
    this.registerEventBusListeners();
    this.fetchTypesWorkStatus()

  },
  beforeDestroy() {
    this.deregisterEventBusListeners();
  },
  setup() {
    const ASSET_APP_STORE_MODULE_NAME = 'app-asset'

    // Register module
    if (!store.hasModule(ASSET_APP_STORE_MODULE_NAME)) store.registerModule(ASSET_APP_STORE_MODULE_NAME, assetStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ASSET_APP_STORE_MODULE_NAME)) store.unregisterModule(ASSET_APP_STORE_MODULE_NAME)
    })

    const user = localStorage.userData != undefined ? JSON.parse(localStorage.userData) : {}

    const statusOptions = [
      'on',
      'off',
      'noconnection',
      'severe',
      'iddle',
      'failure'
    ]

    const {
      fetchAssets,
      tableColumns,
      perPage,
      currentPage,
      totalAssets,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      sortDesc,
      refAssetListTable,

      statusFilter,
      customerId,
      chosenStatus,

      refetchData,
      resolveModelAvatarVariant,
      resolveAssetStatusVariantAndIcon,
      resolveIcon
    } = useAssetList()

    return {
      fetchAssets,
      tableColumns,
      perPage,
      currentPage,
      totalAssets,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAssetListTable,
      sortDesc,

      statusFilter,
      customerId,
      chosenStatus,

      refetchData,

      statusOptions,

      avatarText,
      resolveAssetStatusVariantAndIcon,
      resolveModelAvatarVariant,
      resolveIcon,
      user
    }
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.badge-glow.pulse {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 rgba(220, 53, 69, 0.8);
  }
  70% {
    box-shadow: 0 0 10px 10px rgba(220, 53, 69, 0);
  }
  100% {
    box-shadow: 0 0 0 rgba(220, 53, 69, 0);
  }
}

.noconnection {
  color: #726f7f !important;
}

.per-page-selector {
  width: 90px;
}

.confirmBtn {
  background-color: #7367f0 !important;
  margin-left: 0.5em;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}


</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
